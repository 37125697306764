import { ACADEMIC_YEAR } from "./ACADEMIC_YEAR";
import { AREAS } from "./AREAS";
import { LANGUAGE } from "./LANGUAGE";
import { ODS } from "./ODS";
import { PROGRAM_TYPE } from "./PROGRAM_TYPE";
import { QUICK_FACTS } from "./QUICK_FACTS";

export default {
	AREAS,
	LANGUAGE,
	PROGRAM_TYPE,
	QUICK_FACTS,
	ODS,
	ACADEMIC_YEAR,
};
