import { VideoCardCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	detail,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<VideoCardCollectionProps> = {
	schemaType: "module",
	component: "VideoCardCollection",
	category: "collection",
	displayName: "Video Card Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					hideable: true,
					mandatory: false,
				},
				{
					...detail,
					hideable: true,
					mandatory: false,
				},
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["VideoCard"],
					contentType: "components",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/VideoCardCollection/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/VideoCardCollection/Layouts/layout2.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
			],
		},
	],

	default: {
		component: "VideoCardCollection",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		elements: [],
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "tertiary",
		},
		anchorID: null,
		layout: "layout1",
		verticalSpacing: "medium",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/VideoCardCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/VideoCardCollection/thumbnail@2x.png",
	},
};

export default schema;
