import { NewsListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { detail, heading } from "@schemas/presets";

const schema: Schema.ListTemplate<NewsListProps> = {
	dataPacks: ["NEWS_PACK"],
	schemaType: "template",
	displayName: "News List",
	component: "NewsList",
	type: { label: "The items", value: "items", mode: "list" },

	content: [
		{ ...detail },
		{ ...heading, title: "Subtitle", key: "subtitle", mandatory: false },
		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: [
				"AccordionCollection",
				"Accreditations",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"CypherCollection",
				"DownloadCollection",
				"EventDistributor",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"Files",
				"FullImageWithText",
				"FullVideo",
				"HighlightCarousel",
				"HighlightQuote",
				"ImageBanner",
				"LandingIntro",
				"LocationMap",
				"LogoCollection",
				"ModalLinkCollection",
				"NewsHighlightDistributor",
				"NewsSimpleDistributor",
				"OdsCollection",
				"OrderedCollection",
				"ProgramsDistributor",
				"QuoteTestimonial",
				"QuoteTestimonialCollection",
				"SocialShare",
				"TabsModules",
				"VideoCardCollection",
				"Wysiwyg",

				// TODO: Remove this line after QA module (this module is only for landings)
				"IntroForm",
			],
			key: "mainSection",
			contentType: "modules",
		},
		{
			type: "ReferenceField",
			title: "Members",
			sources: [{ structuredData: "NEWS" }],
			selectionType: ["auto", "manual"],
			key: "data",
		},

		{
			title: "Items/page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 1,
			max: 6,
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "NewsList",
		itemsPerPage: 6,
		hasDistributorData: true,
		activePage: 1,
		detail: "Lorem ipsum paragraph",
		subtitle: { content: "Lorem Ipsum", tag: "h2" },
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
		data: {
			mode: "auto",
			sources: [
				{
					structuredData: "NEWS",
				},
			],
			/* ---------- */
			/* opcionales */
			/* ---------- */
			// order: 'alpha-ASC',
			// filter: ['string'],
			// fullRelations: true,
			// quantity: 2
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/NewsList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/NewsList/thumbnail@2x.png",
	},
};

export default schema;
