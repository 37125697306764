import { ResearchProjectsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, themeSelector, verticalSpacing } from "@schemas/presets";

const schema: Schema.Module<ResearchProjectsDistributorProps> = {
	dataPacks: ["RESEARCH_PROJECTS_PACK"],
	schemaType: "module",
	component: "ResearchProjectsDistributor",
	category: "distributors",
	displayName: "ResearchProjectsDistributor",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ReferenceField",
					title: "Research Projects",
					key: "data",
					sources: [{ structuredData: "RESEARCH_PROJECTS" }],
					selectionType: ["auto"],
					hidden: true,
				},

				{
					type: "CheckGroup",
					key: "distributorOptions",
					title: "Distributor Options",
					options: [
						{ name: "search", title: "Search" },
						{ name: "filters", title: "Filters" },
					],
				},

				{
					title: "Items/page",
					type: "NumberField",
					key: "itemsPerPage",
					min: 1,
					max: 6,
					mandatory: true,
				},

				{
					type: "TextField",
					key: "noResultsText",
					title: "No result text",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "ResearchProjectsDistributor",
		data: {
			mode: "auto",
			quantity: 0,
			sources: [{ structuredData: "RESEARCH_PROJECTS" }],
		},
		hasDistributorData: true,
		anchorID: null,
		verticalSpacing: "small",
		subtheme: "default",
		itemsPerPage: 6,
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		distributorOptions: ["search", "filters"],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ResearchProjectsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ResearchProjectsDistributor/thumbnail@2x.png",
	},
};

export default schema;
