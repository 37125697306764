import AnchorTab from "./AnchorTab";
import Button from "./Button";
import HeroQuickfactComponent from "./HeroQuickfactComponent";
import Image from "./Image";
import SeminarFilters from "./SeminarFilters";
import OdsCardModal from "../components/Cards/OdsCardModal";

export default {
	AnchorTab,
	Button,
	HeroQuickfactComponent,
	Image,
	OdsCardModal,
	SeminarFilters,
};
