import { SeminarListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import SeminarDistributor from "@schemas/modules/SeminarDistributor";
import { detail } from "@schemas/presets";

const schema: Schema.Template<SeminarListProps> = {
	dataPacks: ["EVENTS_PACK"],
	schemaType: "template",
	displayName: "Seminar List",
	component: "SeminarList",
	type: { label: "The items", value: "items", mode: "list" },

	content: [
		{ ...detail },

		{
			type: "ComponentContainer",
			key: "seminarDistributor",
			title: "Seminar Distributor",
			whiteList: ["SeminarDistributor"],
		},

		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			contentType: "modules",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"CypherCollection",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"Files",
				"FullImageWithText",
				"FullVideo",
				"HighlightQuote",
				"LandingIntro",
				"LocationMap",
				"LogoCollection",
				"ModalLinkCollection",
				"NewsHighlightDistributor",
				"NewsSimpleDistributor",
				"OdsCollection",
				"OrderedCollection",
				"ProgramsDistributor",
				"QuoteTestimonial",
				"QuoteTestimonialCollection",
				"SocialShare",
				"TabsModules",
				"Wysiwyg",
			],
		},
		{
			title: "Items/page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 1,
			max: 6,
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "SeminarList",
		itemsPerPage: 6,
		detail: "Lorem ipsum paragraph",
		seminarDistributor: {
			...SeminarDistributor.default,
			distributorOptions: ["search", "filters"],
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/SeminarList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/SeminarList/thumbnail@2x.png",
	},
};

export default schema;
