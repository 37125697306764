import { ResearchProjectDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { defaultLink, link } from "@schemas/presets";

const schema: Schema.Template<ResearchProjectDetailProps> = {
	schemaType: "template",
	displayName: "Research Project Detail",
	component: "ResearchProjectDetail",
	dataPacks: ["RESEARCH_PROJECTS_PACK"],
	type: { label: "Static", value: "static", mode: "detail" },

	content: [
		{
			title: "Reference",
			type: "TextField",
			key: "reference",
			mandatory: true,
		},
		{
			title: "Researcher",
			type: "TextField",
			key: "researcher",
			mandatory: true,
		},
		{
			title: "Duration",
			type: "TextField",
			key: "duration",
		},
		{
			title: "Funding",
			type: "TextField",
			key: "funding",
		},
		{
			title: "Project category",
			type: "MultiCheckSelect",
			key: "projectType",
			source: "RESEARCH_PROJECT_TYPE",
			placeholder: "Project type",
		},
		{
			...link,
			title: "More info",
			key: "link",
		},
	],

	default: {
		type: "template",
		templateType: "ResearchProjectDetail",
		reference: "",
		researcher: "",
		duration: "",
		funding: "",
		projectType: [],
		link: {
			...defaultLink,
			text: "More info",
			variant: "primary",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ResearchProjectDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ResearchProjectDetail/thumbnail@2x.png",
	},
};

export default schema;
