import { ResearchProjectsListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import ResearchProjectsDistributor from "@schemas/modules/ResearchProjectsDistributor";
import { detail } from "@schemas/presets";

const schema: Schema.ListTemplate<ResearchProjectsListProps> = {
	dataPacks: ["RESEARCH_PROJECTS_PACK"],
	schemaType: "template",
	displayName: "Research Projects List",
	component: "ResearchProjectsList",
	type: { label: "The items", value: "items", mode: "list" },

	content: [
		{ ...detail },

		{
			type: "ComponentContainer",
			title: "Distributor",
			whiteList: ["ResearchProjectsDistributor"],
			key: "distributor",
			hideable: false,
		},
	],

	default: {
		type: "template",
		templateType: "ResearchProjectsList",
		detail: "Lorem ipsum paragraph",
		distributor: ResearchProjectsDistributor.default,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ResearchProjectsList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ResearchProjectsList/thumbnail@2x.png",
	},
};

export default schema;
