/* eslint-disable import/order */

// ui/objects schemas
import templates from "./src/schemas/templates";
import objects from "./src/schemas/frontless";
import components from "./src/schemas/components";
import modules from "./src/schemas/modules";
import richTextConfig from "./src/schemas/config/rich-text";

// config schemas
import {
	languages,
	menuItems,
	menus,
	moduleCategories,
	themes,
} from "./src/schemas/config";

// contentTypes schemas
import {
	categories,
	pageContentType,
	simpleContentType,
} from "./src/schemas/content-types";

// datapacks schemas
import { dataPacksCategories, dataPacks } from "./src/schemas/data-packs";

// bundle for API
const schemas = {
	config: {
		menus,
		themes,
		languages,
		menuItems,
		moduleCategories,
		richTextConfig,
	},

	ui: {
		modules,
		templates,
		components: { ...components, ...objects },
	},

	contentTypes: {
		dataPacks,
		dataPacksCategories,
		structuredData: {
			...simpleContentType,
			...pageContentType,
			...categories,
		},
	},
};

export default { schemas };
