import { SeminarDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import SeminarFilters from "@schemas/frontless/SeminarFilters";
import {
	anchorID,
	detail,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<SeminarDistributorProps> = {
	schemaType: "module",
	component: "SeminarDistributor",
	category: "distributors",
	displayName: "SeminarDistributor",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },

				{ ...detail },

				{
					type: "ReferenceField",
					title: "Events",
					sources: [{ structuredData: "EVENTS" }],
					selectionType: ["auto", "manual"],
					key: "data",
				},

				{
					type: "CheckGroup",
					key: "distributorOptions",
					title: "Distributor Options",
					options: [
						{ name: "search", title: "Search" },
						{ name: "filters", title: "Filters" },
						{ name: "academicYear", title: "Academic year" },
					],
				},

				{
					type: "ComponentContainer",
					title: "Seminar filters",
					key: "seminarFilters",
					whiteList: ["SeminarFilters"],
				},

				{
					type: "TextField",
					key: "noResultsText",
					title: "No result text",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "SeminarDistributor",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		data: {
			mode: "auto",
			order: "eventDate-ASC",
			quantity: 0,
			sources: [{ structuredData: "EVENTS" }],
		},
		seminarFilters: {
			...SeminarFilters.default,
		},
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		distributorOptions: [],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/SeminarDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/SeminarDistributor/thumbnail@2x.png",
	},
};

export default schema;
