import { FooterProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	defaultAltSubtheme,
	defaultLink,
	defaultSubtheme,
	heading,
	link,
	themeSelector,
	wysiwyg,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const theme = { ...themeSelector };
theme.options = [defaultSubtheme, defaultAltSubtheme];

const schema: Schema.Footer<FooterProps> = {
	schemaType: "module",
	displayName: "Footer",
	component: "Footer",
	type: "footer",
	defaultNavigation: true,

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Main menu name",
					key: "title",
					type: "TextField",
					placeholder: "Type a title",
					mandatory: true,
				},
				{ ...heading, key: "titleFooter" },
				{
					...wysiwyg,
					title: "Content",
					key: "content",
					hideable: true,
				},
				{
					...wysiwyg,
					title: "Contact",
					key: "contact",
					hideable: true,
				},
				{
					title: "Show SEO Menu",
					type: "RadioGroup",
					key: "showSeoMenu",
					mandatory: true,
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{
					title: "Show Social Media",
					type: "RadioGroup",
					key: "showSocialMedia",
					mandatory: true,
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},

				{ ...link, title: "Link (botón contacto)", hideable: true },

				{
					title: "Copyright",
					type: "TextField",
					key: "copyright",
					placeholder: "Type a copyright",
					hideable: true,
				},
				{
					title: "Accreditation title",
					type: "TextField",
					key: "accreditationTitle",
					hideable: true,
				},
				{
					...richtext,
					title: "Copyright note",
					key: "copyrightNote",
					hideable: true,
				},
				{
					title: "Show menu Legal",
					type: "RadioGroup",
					key: "showMenuLegal",
					mandatory: true,
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					title: "Set as default",
					key: "setAsDefault",
					options: [
						{
							title: "Set as Footer default",
						},
					],
				},
				{
					title: "Select Menu SEO",
					key: "seoMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					helptext: "Select the site menu. It must first be created.",
				},
				{
					title: "Select menu Legal",
					key: "legalPages",
					type: "AsyncSelect",
					entity: "menu_containers",
					helptext: "Select the site menu. It must first be created.",
					mandatory: true,
				},
				theme,
			],
		},
	],

	default: {
		component: "Footer",
		type: "footer",
		titleFooter: {
			tag: "h2",
			content: "CUNEF Universidad",
		},
		title: "Lorem Ipsum",
		content: "Lorem ipsum parragraf",
		contact: "Lorem ipsum parragraf",
		showSeoMenu: true,
		showSocialMedia: true,
		link: {
			...defaultLink,
			text: "Contact us",
			variant: "primary",
		},
		accreditationTitle: "Insignia QS Rated Excellence",
		copyright: "© 2023. Todos los derechos reservados",
		copyrightNote: "Lorem ipsum parragraf",
		showMenuLegal: true,
		setAsDefault: false,
		seoMenu: undefined,
		legalPages: undefined,
		subtheme: "default-alt",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Footer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Footer/thumbnail@2x.png",
	},
};

export default schema;
