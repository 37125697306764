import { EVENTS } from "./EVENTS";
import { NEWS } from "./NEWS";
import { PROFESSORS } from "./PROFESSORS";
import { PROGRAM } from "./PROGRAM";
import { RESEARCH_PROJECTS } from "./RESEARCH_PROJECTS";

export default {
	NEWS,
	PROFESSORS,
	PROGRAM,
	EVENTS,
	RESEARCH_PROJECTS,
};
