import { EVENTS_PACK } from "./EVENTS_PACK";
import { LANDINGS_PACK } from "./LANDINGS_PACK";
import { NEWS_PACK } from "./NEWS_PACK";
import { ODS_PACK } from "./ODS_PACK";
import { PROFESSORS_PACK } from "./PROFESSORS_PACK";
import { PROGRAMS_PACK } from "./PROGRAMS_PACK";
import { RESEARCH_PROJECTS_PACK } from "./RESEARCH_PROJECTS_PACK";

export default {
	LANDINGS_PACK,
	EVENTS_PACK,
	NEWS_PACK,
	ODS_PACK,
	PROFESSORS_PACK,
	PROGRAMS_PACK,
	RESEARCH_PROJECTS_PACK,
};
