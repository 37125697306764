import { Schema } from "@griddo/core";

export const RESEARCH_PROJECTS: Schema.PageContentType = {
	dataPacks: ["RESEARCH_PROJECTS_PACK"],
	title: "Research Projects",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	editable: true,
	schema: {
		templates: ["ResearchProjectDetail"],
		fields: [
			{
				type: "TextField",
				key: "title",
				title: "Title",
				from: "title",
			},
			{
				title: "Reference",
				type: "TextField",
				key: "reference",
				from: "reference",
				mandatory: true,
			},
			{
				title: "Researcher",
				type: "TextField",
				key: "researcher",
				from: "researcher",
				mandatory: true,
			},
			{
				title: "Duration",
				type: "TextField",
				key: "duration",
				from: "duration",
			},
			{
				title: "Funding",
				type: "TextField",
				key: "funding",
				from: "funding",
			},
			{
				title: "Project category",
				type: "MultiCheckSelect",
				key: "projectType",
				from: "projectType",
				source: "RESEARCH_PROJECT_TYPE",
				placeholder: "Project type",
			},
			{
				type: "ComponentContainer",
				title: "Button",
				key: "link",
				from: "link",
				whiteList: ["Button"],
			},
		],
	},
};
