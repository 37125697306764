import { VideoCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<VideoCardProps> = {
	schemaType: "component",
	component: "VideoCard",
	displayName: "VideoCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Video",
					key: "video",
					whiteList: ["VideoComponent"],
				},
				{
					...richtext,
					title: "Quote",
					key: "quote",
				},
				{
					title: "Show Quote Icon",
					type: "RadioGroup",
					key: "showQuoteIcon",
					mandatory: true,
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{
					type: "TextField",
					key: "name",
					title: "Name",
					hideable: true,
				},
				{
					type: "TextField",
					key: "position",
					title: "Position",
					hideable: true,
				},
				{
					type: "TextField",
					key: "promotion",
					title: "Promotion",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "VideoCard",
		video: {
			component: "VideoComponent",
		},
		quote: "Lorem ipsum",
		showQuoteIcon: true,
		name: "Lorem ipsum",
		position: "Lorem ipsum",
		promotion: "Lorem ipsum",
	},

	thumbnails: {
		"1x": "/thumbnails/components/VideoCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/VideoCard/thumbnail@2x.png",
	},
};

export default schema;
