import { SeminarFiltersProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<SeminarFiltersProps> = {
	schemaType: "component",
	component: "SeminarFilters",
	displayName: "Seminar Filters",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ReferenceField",
					title: "Categories",
					helptext: "Select the categories you want to show in filters",
					sources: [{ structuredData: "EVENTS_CATEGORY" }],
					selectionType: ["manual"],
					key: "eventCategoryFilters",
				},
				{
					type: "ReferenceField",
					title: "Academic year",
					helptext: "Select the academic years you want to show in filters",
					sources: [{ structuredData: "ACADEMIC_YEAR" }],
					selectionType: ["manual"],
					key: "academicYearFilters",
				},
			],
		},
	],

	default: {
		component: "SeminarFilters",
		hasDistributorData: true,
		categoriesData: {
			sources: [{ structuredData: "EVENTS_CATEGORY" }],
			mode: "manual",
			order: "alpha",
			quantity: 0,
		},
		academicYearData: {
			sources: [{ structuredData: "ACADEMIC_YEAR" }],
			mode: "manual",
			order: "alpha",
			quantity: 0,
		},
	},
};

export default schema;
