import { AccreditationsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	image,
	themeSelector,
	verticalSpacing,
	wysiwyg,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<AccreditationsProps> = {
	schemaType: "module",
	component: "Accreditations",
	category: "content",
	displayName: "Accreditations",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...richtext,
					title: "Title",
					key: "title",
					hideable: false,
				},
				{
					...wysiwyg,
					title: "Quote",
					key: "quote",
					hideable: false,
					mandatory: true,
				},
				{ ...image },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{ ...themeSelector },
				{ ...verticalSpacing },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					mandatory: true,
					options: [
						{
							value: "layout001",
							img: "/thumbnails/modules/BasicContent/Layouts/layout1.png",
						},
						{
							value: "layout002",
							img: "/thumbnails/modules/BasicContent/Layouts/layout2.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "Accreditations",
		title: "",
		quote: "",
		layout: "layout001",
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
		image: {
			component: "Image",
			fetchpriority: "high",
			loading: "eager",
			decoding: "sync",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HighlightQuote/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HighlightQuote/thumbnail@2x.png",
	},
};

export default schema;
