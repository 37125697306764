import { EventDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<EventDetailProps> = {
	schemaType: "template",
	displayName: "Event Detail",
	component: "EventDetail",
	dataPacks: ["EVENTS_PACK"],
	type: { label: "Static", value: "static", mode: "detail" },

	content: [
		{
			type: "ImageField",
			title: "Image",
			key: "image",
			mandatory: false,
		},

		{
			type: "DateField",
			key: "eventDate",
			title: "Date",
			mandatory: true,
		},

		{
			type: "TextField",
			title: "Hour",
			key: "hour",
			mandatory: true,
		},

		{
			type: "TextField",
			title: "Place",
			key: "place",
			mandatory: false,
		},

		{
			type: "TextField",
			title: "Event Mode",
			key: "eventMode",
			hideable: true,
			placeholder: "Type format",
		},

		{
			type: "Wysiwyg",
			title: "Content",
			key: "content",
			mandatory: false,
			placeholder: "Type a text",
		},

		{
			title: "Event Category",
			type: "AsyncCheckGroup",
			key: "eventCategory",
			source: "EVENTS_CATEGORY",
		},
		{
			type: "ConditionalField",
			title: "Category type",
			key: "categoryType",
			mandatory: true,
			options: [
				{ title: "Event", name: "event", value: "event" },
				{ title: "Seminary", name: "seminary", value: "seminary" },
			],
			fields: [
				{
					key: "researcher",
					title: "Researcher",
					type: "TextField",
					condition: "seminary",
					mandatory: true,
				},
				{
					key: "institution",
					title: "Institution",
					type: "TextField",
					condition: "seminary",
					mandatory: true,
				},
				{
					type: "MultiCheckSelect",
					key: "academicCourseYear",
					source: "ACADEMIC_YEAR",
					condition: "seminary",
					placeholder: "Select an academic course year",
					title: "Academic course year",
					mandatory: true,
				},
				{
					type: "UniqueCheck",
					title: "Show event categories?",
					key: "showEventCategories",
					condition: "seminary",
					options: [{ title: "Show" }],
				},
			],
		},
		{
			type: "ComponentContainer",
			title: "More Info Button",
			key: "moreInfoButton",
			whiteList: ["Button"],
			hideable: true,
		},
		{
			type: "FieldGroup",
			key: "downloadButton",
			title: "Download Button",
			fields: [
				{
					type: "TextField",
					title: "Download Label",
					key: "downloadLabel",
					hideable: true,
				},
				{
					title: "Download File",
					type: "FileField",
					key: "downloadFile",
				},
			],
		},
		{
			type: "ComponentContainer",
			title: "Form",
			key: "form",
			whiteList: ["EventForm"],
			hideable: true,
		},
		{
			type: "VisualUniqueSelection",
			title: "Layout",
			key: "layout",
			columns: 2,
			mandatory: true,
			options: [
				{
					value: "layout001",
					img: "/thumbnails/modules/QuoteTestimonial/Layouts/layout1.png",
				},
				{
					value: "layout002",
					img: "/thumbnails/modules/QuoteTestimonial/Layouts/layout2.png",
				},
			],
		},
	],

	default: {
		type: "template",
		templateType: "EventDetail",
		image: undefined,
		eventDate: "",
		hour: "",
		place: "",
		eventMode: "",
		content: "Lorem ipsum",
		categoryType: "event",
		academicCourseYear: undefined,
		eventCategory: [],
		downloadLabel: undefined,
		downloadFile: undefined,
		researcher: "",
		institution: "",
		form: {
			component: "EventForm",
			title: {
				content: "Lorem ipsum",
				tag: "h3",
			},
			content: "Lorem ipsum paragraph",
			buttonLabel: "Enviar",
			data: {
				mode: "auto",
				order: "alpha",
				quantity: 0,
				sources: [{ structuredData: "PROGRAM" }],
			},
		},
		showEventCategories: true,
		moreInfoButton: {
			component: "Button",
			url: {
				newTab: true,
				noFollow: false,
				href: "",
			},
		},
		layout: "layout002",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/EventDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/EventDetail/thumbnail@2x.png",
	},
};

export default schema;
